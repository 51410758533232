import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import '@angular/common/locales/global/ru';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { JwtModule } from '@auth0/angular-jwt';
import {
  FaIconLibrary,
  FontAwesomeModule,
} from '@fortawesome/angular-fontawesome';
import {
  faCalendarAlt,
  faCheckSquare,
  faPlusSquare,
  faQuestionCircle,
  faSquare,
  faTimesCircle,
  faTrashAlt,
  faUserCircle,
} from '@fortawesome/free-regular-svg-icons';
import {
  faArrowLeft,
  faArrowRight,
  faCheck,
  faChevronLeft,
  faChevronRight,
  faCloudDownloadAlt,
  faDownload,
  faEdit,
  faExclamationTriangle,
  faFilter,
  faSortDown,
  faSortUp,
  faSpinner,
  faSync,
  faTimes,
  faFileAlt,
} from '@fortawesome/free-solid-svg-icons';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastNoAnimationModule, ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { AuthInterceptor } from './inperceptors/auth.interceptor';
import { interceptorProviders } from './inperceptors/interceptors';
import { LoaderInterceptorService } from './inperceptors/loader-interceptor';
import { HomeComponent } from './pages/home/home.component';
import { LoginComponent } from './pages/login/login.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { SignupComponent } from './pages/signup/signup.component';
import { PopupmessageService } from './services/popupmessage.service';
import { LoaderComponent } from './components/loader/loader.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatProgressBarModule } from '@angular/material/progress-bar';

export function tokenGetter(): string {
  return localStorage.getItem('token');
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    NotFoundComponent,
    LoginComponent,
    SignupComponent,
    LoaderComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    FontAwesomeModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    JwtModule.forRoot({
      config: {
        tokenGetter,
        disallowedRoutes: [
          'https://p2p-admin/api/v1/auth',
          'https://p2p-admin-dev/api/v1/auth',
          'https://localhost:4200/api/v1/auth',
        ],
        authScheme: () => '',
      },
    }),
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-bottom-left',
    }),
    ToastNoAnimationModule.forRoot(),
    BrowserAnimationsModule,
    MatProgressBarModule,
  ],
  providers: [
    [
      { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
      {
        provide: HTTP_INTERCEPTORS,
        useClass: LoaderInterceptorService,
        multi: true,
      },
    ],
    { provide: LOCALE_ID, useValue: 'ru' },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(
      faPlusSquare,
      faQuestionCircle,
      faUserCircle,
      faTimesCircle,
      faSquare,
      faCheckSquare,
      faTrashAlt,
      faCalendarAlt,
      faFileAlt
    );
    library.addIcons(
      faDownload,
      faCloudDownloadAlt,
      faTimes,
      faChevronLeft,
      faChevronRight,
      faArrowRight,
      faArrowLeft,
      faExclamationTriangle,
      faFilter,
      faSpinner,
      faSortUp,
      faSortDown,
      faSync,
      faCheck,
      faEdit
    );
  }
}
