import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SoundService {
  isSoundOn: boolean = false;
  //audio: HTMLAudioElement = new Audio('../assets/addnewbid.mp3');
  bidSoundUrl: string = '../assets/addnewbid.mp3';

  constructor() {
    this.init();
  }
  play() {
    const a = new Audio(this.bidSoundUrl);
    return a.play();
  }

  init(): void {
    //this.audio.autoplay = false;
    this.isSoundOn = JSON.parse(localStorage.getItem('soundToggle'));
    //this.audio.load();
    this.playBidSound();
  }
  isOn(): boolean {
    return this.isSoundOn;
  }
  toggle(): void {
    this.isSoundOn = !this.isSoundOn;
    localStorage.setItem('soundToggle', JSON.stringify(this.isSoundOn));
    if (this.isSoundOn) {
      this.playBidSound();
    }
  }
  playBidSound(): void {
    if (!this.isSoundOn) return;
    // this.audio.src = this.bidSoundUrl;
    //this.audio.load();
    // this.audio.play();
    this.play();
  }
}
