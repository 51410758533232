<main>
  <div class="container d-flex justify-content-center align-items-center">
    <div class="card">
      <div class="card-body">
        <div *ngIf="!tempToken">
          <form class="form" [formGroup]="loginForm" (ngSubmit)="loginForm.markAllAsTouched(); submit()">
          <h2 class="card-title mb-4">Вход</h2>
          <fieldset>
            <div class="form-group">
              <input class="form-control" placeholder="Имя пользователя" formControlName="username" type="text"
                id="username">
            </div>
          </fieldset>
          <fieldset>
            <div class="form-group">
              <input class="form-control" placeholder="Пароль" formControlName="password" type="password" id="password">
            </div>
          </fieldset>
          <div class="alert alert-danger" *ngIf="errorMessage">{{ errorMessage }}</div>
          <button type="submit" class="btn btn-primary mt-3">Вход</button>
          </form>
        </div>
        <div *ngIf="tempToken">
          <form class="form" [formGroup]="authForm" (ngSubmit)="authForm.markAllAsTouched(); submitAuth()">
          <h2 class="card-title mb-4">Авторизация</h2>
          <fieldset>
            <div class="form-group">
              <input class="form-control" placeholder="_ _ _ _ _ _" formControlName="code" type="text" id="code">
            </div>
          </fieldset>
          <div class="alert alert-danger" *ngIf="errorMessage">{{ errorMessage }}</div>
          <button type="submitAuth" class="btn btn-primary mt-3">Авторизация</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</main>