import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { ProgressLoaderService } from 'src/app/services/progress-loader.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent implements OnInit {
  isLoading: boolean = false;
  value: number;
  maxValue: number;
  constructor(
    private loaderService: ProgressLoaderService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.loaderService.isLoading.subscribe((res) => {
      this.isLoading = res.isLoading;
      this.value = res.value;
      this.maxValue = res.maxValue;
    });
  }
}
