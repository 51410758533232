import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import {
  LoginRequest,
  Login2faRequest,
  LoginResponse,
  RefreshTokenRequest,
  RefreshTokenResponse,
} from '../models/auth';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  @Output() userChanged: EventEmitter<string> = new EventEmitter();
  private USER_ROLE: string = '';
  private userData = { username: '' };
  private TOKEN: string = '';
  private REFRESH_TOKEN: string = '';

  constructor(private http: HttpClient, private jwtHelper: JwtHelperService) {}

  login(username: string, password: string): Observable<LoginResponse> {
    const loginRequest = new LoginRequest(username, password);
    const request = this.http
      .post<LoginResponse>('/api/v1/authv2/logIn', loginRequest)
      .pipe(
        tap((res: LoginResponse) => {
          if (res.result?.token && res.result?.refreshToken) {
            this.loginSuccess(res);
          }
          if (res.result?.tempToken) {
            return res;
          }
        })
      );
    return request;
  }

  ///========== Login TO FA ============
  login2fa(
    username: string,
    tempToken: string,
    code: string
  ): Observable<LoginResponse> {
    const loginRequest = new Login2faRequest(username, tempToken, code);
    const request = this.http
      .post<LoginResponse>('/api/v1/authv2/logInTwoFa', loginRequest)
      .pipe(
        tap((res: LoginResponse) => {
          if (res.result?.token && res.result?.refreshToken) {
            this.loginSuccess(res);
          }
        })
      );
    return request;
  }

  logout(): Observable<any> {
    const request = this.http
      .post<any>('/api/v1/authv2/logOut', {})
      .pipe(finalize(this.logOutSuccess.bind(this)));

    return request;
  }
  refreshToken(): Observable<RefreshTokenResponse> {
    const refreshToken = localStorage.getItem('refreshToken');
    const refreshTokenRequest = new RefreshTokenRequest(refreshToken);
    const request = this.http
      .post<RefreshTokenResponse>(
        '/api/v1/authv2/refreshToken',
        refreshTokenRequest
      )
      .pipe(
        tap((res: RefreshTokenResponse) => {
          this.refreshTokenSuccess(res);
        })
      );

    return request;
  }
  clearStateAndLocalStorage(): void {
    this.TOKEN = '';
    this.REFRESH_TOKEN = '';
    this.USER_ROLE = '';
    this.userData = { username: '' };
    this.removeTokensFromLocalStorage();
    this.removeUserDataFromLocalStorage();
  }
  private refreshTokenSuccess({ result }): void {
    const { refreshToken, token } = result;
    this.TOKEN = token;
    this.REFRESH_TOKEN = refreshToken;
    this.storeTokensInLocalStorage();
  }
  private loginSuccess({ result }) {
    const { refreshToken, token, role, username } = result;
    this.TOKEN = token;
    this.REFRESH_TOKEN = refreshToken;
    this.USER_ROLE = role;
    this.userData = { username };
    this.storeTokensInLocalStorage();
    this.decodeTokenAndStoreData();
    this.userChanged.emit(username);

    // Сохранить токены
    // Сохранить данные пользователя
    // Сохранить РОЛЬ
  }
  private logOutSuccess(): void {
    this.removeTokensFromLocalStorage();
    this.removeUserDataFromLocalStorage();
    this.userChanged.emit('');
  }
  private storeTokensInLocalStorage(): void {
    localStorage.setItem('token', this.TOKEN);
    localStorage.setItem('refreshToken', this.REFRESH_TOKEN);
  }
  private removeTokensFromLocalStorage(): void {
    localStorage.setItem('token', '');
    localStorage.setItem('refreshToken', '');
  }
  private decodeTokenAndStoreData(): void {
    const tokenData = this.jwtHelper.decodeToken(this.TOKEN);
    const { operatorId, role, isAdmin } = tokenData;
    localStorage.setItem('isAdmin', isAdmin.toString());
    localStorage.setItem('isRoot', (role === 'root').toString());
    localStorage.setItem('operatorId', operatorId);
    /////
    localStorage.setItem('username', this.userData.username);
    localStorage.setItem('role', role);
  }

  private removeUserDataFromLocalStorage(): void {
    localStorage.removeItem('username');
    localStorage.removeItem('isAdmin');
    localStorage.removeItem('isRoot');
    localStorage.removeItem('operatorId');
  }

  get token(): string {
    return localStorage.getItem('token');
  }

  set token(token: string) {
    localStorage.setItem('token', token);
  }

  get username(): string {
    return localStorage.getItem('username');
  }

  get operatorId(): string {
    return localStorage.getItem('operatorId');
  }

  get isAuthenticated(): boolean {
    return !!this.token;
  }

  get isRoot(): boolean {
    // let isRoot = localStorage.getItem('username') === 'root' ? true : false; // Поменять после добавления ролей!!!!
    return localStorage.getItem('isRoot') === 'true';
    //return isRoot;
  }

  get isAdmin(): boolean {
    return localStorage.getItem('isAdmin') === 'true';
  }

  get userRole(): string {
    // if (this.isRoot) {
    //   return 'root';
    // } else if (this.isAdmin) {
    //   return 'admin';
    // } else {
    //   return 'operator';
    // }
    return localStorage.getItem('role');
  }
}
