<header>
  <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
    <div class="container-fluid">
      <a routerLink="/" class="navbar-brand">{{ title }}</a>
      <button
        class="navbar-toggler"
        type="button"
        (click)="isCollapsed = !isCollapsed"
        aria-expanded="false"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" [ngbCollapse]="isCollapsed">
        <ul class="nav navbar-nav mr-auto">
          <li class="nav-item" *ngIf="username">
            <div class="form-check pl-0">
              <div
                class="custom-control custom-switch"
                (click)="soundToggle($event)"
              >
                <input
                  type="checkbox"
                  class="custom-control-input"
                  [checked]="soundOn()"
                  [id]="soundToggle - trigger"
                />
                <label
                  class="custom-control-label text-white ml-3"
                  title="Домен активен"
                  >{{ soundOn() ? "Вкл" : "Выкл" }}</label
                >
              </div>
            </div>
          </li>
          <!-- <li class="nav-item" *ngIf="username">
            <a class="nav-link" routerLink="/" routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }">Home</a>
          </li>
          <li class="nav-item" *ngIf="username">
            <a class="nav-link" routerLink="/dashboard/orders" routerLinkActive="active">Orders</a>
          </li>
          <li class="nav-item" *ngIf="username">
            <a class="nav-link" routerLink="/dashboard/operators" routerLinkActive="active">Operators</a>
          </li>
          <li class="nav-item" *ngIf="username">
            <a class="nav-link" routerLink="/dashboard/users" routerLinkActive="active">Users</a>
          </li>
          <li class="nav-item" *ngIf="username">
            <a class="nav-link" routerLink="/dashboard/merchants" routerLinkActive="active">Merchants</a>
          </li>
          <li class="nav-item" *ngIf="username">
            <a class="nav-link" routerLink="/dashboard/transactions" routerLinkActive="active">Transactions</a>
          </li> -->
        </ul>
        <ul class="nav navbar-nav">
          <li class="nav-item" *ngIf="username">
            <a class="nav-link" routerLink="/dashboard/account">
              <fa-icon
                class="mr-1"
                [icon]="['far', 'user-circle']"
                [fixedWidth]="true"
              ></fa-icon
              >{{ username
              }}<span class="badge badge-info ml-3" *ngIf="isAdmin">админ</span>
            </a>
          </li>
        </ul>
        <form class="form-inline">
          <a
            class="btn btn-link text-white ml-3"
            routerLink="/login"
            *ngIf="!username"
            >Вход</a
          >
          <a
            class="btn btn-primary text-white ml-3"
            routerLink="/signup"
            *ngIf="!username"
            >Регистрация</a
          >
          <button
            class="btn btn-outline-secondary text-white ml-3"
            (click)="logout()"
            *ngIf="username"
          >
            Выход
          </button>
        </form>
      </div>
    </div>
  </nav>
</header>
