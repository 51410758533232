import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { ProgressLoaderService } from '../services/progress-loader.service';

@Injectable()
export class LoaderInterceptorService implements HttpInterceptor {
  private requests: HttpRequest<any>[] = [];
  maxValue: number;
  nowValue: number;
  noShowLoaderUrls = [
    '/api/v1/index/getRate',
    '/api/v1/bid/getBidList',
    '/api/v1/profitPercent/getProfitPercent',
  ];
  constructor(private loaderService: ProgressLoaderService) {}

  removeRequest(req: HttpRequest<any>) {
    const i = this.requests.indexOf(req);
    if (i >= 0) {
      this.requests.splice(i, 1);
    }
    this.loaderService.isLoading.next({
      isLoading: this.requests.length > 0,
      value: this.maxValue - this.requests.length,
      maxValue: this.maxValue,
    });
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (!this.noShowLoaderUrls.includes(req.url)) {
      this.requests.push(req);
      this.maxValue = this.requests.length;
      this.loaderService.isLoading.next({
        isLoading: true,
        maxValue: this.maxValue,
        value: this.nowValue,
      });
    }

    return new Observable((observer) => {
      const subscription = next.handle(req).subscribe(
        (event) => {
          if (event instanceof HttpResponse) {
            this.removeRequest(req);
            observer.next(event);
          }
        },
        (err) => {
          this.removeRequest(req);
          observer.error(err);
        },
        () => {
          this.removeRequest(req);
          observer.complete();
        }
      );
      // remove request from queue when cancelled
      return () => {
        this.removeRequest(req);
        subscription.unsubscribe();
      };
    });
  }
}
