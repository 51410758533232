import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { SoundService } from 'src/app/services/sound.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  isCollapsed = true;
  username = '';
  userSubscription: Subscription;
  title = 'P2P Exchange';

  get isAdmin(): boolean {
    return this.authService.isAdmin;
  }

  constructor(
    private router: Router,
    private authService: AuthService,
    private soundService: SoundService
  ) {}

  ngOnInit(): void {
    this.username = this.authService.username;
    this.userSubscription = this.authService.userChanged.subscribe(
      (username) => (this.username = username)
    );
  }

  logout(): void {
    this.authService.logout().subscribe(
      (result) => {
        this.router.navigate(['/']);
      },
      (err) => {
        this.router.navigate(['/']);
      },
      () => {
        this.router.navigate(['/']);
      }
    );
  }
  soundOn(): boolean {
    return this.soundService.isSoundOn;
  }
  soundToggle($event?: MouseEvent): void {
    if ($event) {
      $event.stopPropagation();
    }
    //this.soundOn = !this.soundOn;
    this.soundService.toggle();
  }
}
