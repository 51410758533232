export class LoginParams {
  constructor(public username: string, public password: string) {}
}

export class LoginRequest {
  params: LoginParams;

  constructor(username: string, password: string) {
    this.params = new LoginParams(username, password);
  }
}

export class RefreshTokenParams {
  constructor(public refreshToken: string) {}
}

export class RefreshTokenRequest {
  params: RefreshTokenParams;
  constructor(refreshToken: string) {
    this.params = new RefreshTokenParams(refreshToken);
  }
}

export class RefreshTokenResult {
  token: string;
  refreshToken: string;
}

export class RefreshTokenResponse {
  method: string;
  result: RefreshTokenResult;
}

export class LoginResult {
  username: string;
  token?: string;
  tempToken?: string;
  refreshToken?: string;
}

export class LoginResponse {
  method: string;
  result: LoginResult;
}

export class Login2faParams {
  constructor(
    public username: string,
    public tempToken: string,
    public code: string
  ) {}
}

export class Login2faRequest {
  params: Login2faParams;

  constructor(username: string, tempToken: string, code: string) {
    this.params = new Login2faParams(username, tempToken, code);
  }
}
