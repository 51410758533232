import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class PopupmessageService {
  messages = {
    1003: 'Не найден.',
    1004: 'У вас нет прав администратора.',
    1005: 'Запись с таким именем уже существует.',
    1006: 'Доступ запрещен! Недостаточно прав:(',

    2000: 'Неверный пароль.',
    2002: 'Код TwoFa неверный',

    2004: 'Пользователь с таким именем не найден.',

    2501: 'Неивестная ошибка:(',
    2502: 'Время редактирования транзакции вышло.',
    2503: 'Произошла ошибка при удалении транзакции, обновление не возможно.',
    2505: 'Транзакция уже включена в отчет. Удаление/обновление транзакции невозможно.',
  };
  constructor(private toastrs: ToastrService) {}
  public handleError(err: HttpErrorResponse) {
    console.log(err);
    let { message } = err;
    if (err.error?.error) {
      const { error } = err.error;
      message = this.messages[error.code] || error.message;
    }
    this.toastrs.error(message);
  }
  public showSuccessMessage(msg) {
    this.toastrs.success(msg);
  }
  public showWarnigMessage(msg) {
    this.toastrs.warning(msg);
  }
}
