<footer>
  <app-loader></app-loader>
  <nav class="navbar navbar-dark bg-dark">
    <div class="container-fluid">
      <div class="navbar-expand mr-auto">
        <div class="navbar-nav">
          <a class="nav-item nav-link" href="/assets/docs/privacy-policy.pdf"
            >Privacy Policy</a
          >
        </div>
      </div>
      <div class="navbar-expand ml-auto">
        <div class="navbar-nav">
          <span class="text-small text-muted"
            >&copy;&nbsp;{{ now | date: "yyyy" }} {{ title }}. All Rights
            Reserved</span
          >
        </div>
      </div>
    </div>
  </nav>
</footer>
