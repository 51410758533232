import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProgressLoaderService {
  isLoading = new BehaviorSubject({ isLoading: false, value: 0, maxValue: 10 });
  constructor() {}
}
