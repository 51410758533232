import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  tempToken = '';
  loginForm = this.fb.group({
    username: ['', Validators.required],
    password: ['', Validators.required],
  });
  authForm = this.fb.group({
    code: ['', Validators.required],
  });
  errorMessage = '';

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private authService: AuthService
  ) {}

  ngOnInit(): void {}

  async submit(): Promise<void> {
    const loginData = this.loginForm.value;
    this.errorMessage = '';

    this.authService.login(loginData.username, loginData.password).subscribe(
      (loginResponse) => {
        const { result } = loginResponse;
        if (result.tempToken === undefined) {
          this.router.navigate(['/dashboard']);
        } else {
          this.tempToken = result.tempToken;
        }
      },
      (err) => {
        console.log('Login Error: ', err);
      }
    );

    // try {
    //   const tempToken = await this.authService.login(loginData.username, loginData.password);

    //   if (tempToken === undefined) {
    //     this.router.navigate(['/dashboard']);
    //   } else {
    //     this.tempToken = tempToken;
    //   }
    // } catch (e) {
    //   this.errorMessage = e;
    // }
  }

  async submitAuth(): Promise<void> {
    const { username } = this.loginForm.value;
    const { code } = this.authForm.value;
    const tempToken = this.tempToken;
    this.errorMessage = '';

    this.authService.login2fa(username, tempToken, code).subscribe(
      (loginResponse) => {
        const { result } = loginResponse;
        if (result) {
          this.router.navigate(['/dashboard']);
        }
      },
      (err) => {
        console.log('login2fa Error: ', err);
        this.errorMessage = err.error?.error?.message;
      }
    );

    // try {
    //   await this.authService.login2fa(
    //     loginData.username,
    //     this.tempToken,
    //     authData.code
    //   );

    //   this.router.navigate(['/dashboard']);
    // } catch (e) {
    //   this.errorMessage = e;
    // }
  }
}
