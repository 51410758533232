import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take, tap } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { PopupmessageService } from '../services/popupmessage.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private isTokenRefreshing: boolean = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject(null);
  constructor(
    private router: Router,
    private authService: AuthService,
    private messageService: PopupmessageService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const token = this.authService.token;
    if (token) {
      request = this.addTokenToRequest(request, token);
    }
    return next.handle(request).pipe(
      catchError((error: any) => {
        if (error instanceof HttpErrorResponse && error.status === 401) {
          return this.tryRefreshToken(request, next);
        } else {
          this.messageService.handleError(error);
          return throwError(error);
        }
      })
    );
  }

  private addTokenToRequest(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        Authorization: token,
      },
    });
  }

  private tryRefreshToken(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isTokenRefreshing) {
      this.isTokenRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.authService.refreshToken().pipe(
        switchMap((response) => {
          this.isTokenRefreshing = false;
          this.refreshTokenSubject.next(response);
          return next.handle(
            this.addTokenToRequest(request, response.result.refreshToken)
          );
        }),
        catchError((err) => {
          this.isTokenRefreshing = false;
          if (err.status === 403) {
            this.authService.clearStateAndLocalStorage();
            this.router.navigate(['login']);
          }
          return throwError(err);
        })
      );
    } else {
      return this.refreshTokenSubject.pipe(
        filter((token) => token != null),
        take(1),
        switchMap((response) => {
          return next.handle(
            this.addTokenToRequest(request, response.result.refreshToken)
          );
        })
      );
    }
  }
}
